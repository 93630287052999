import React from "react"

import styles from "./styles.module.scss"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Confetti from "../../components/confetti"

export default function AboutPage() {
  return (
    <>
      <SEO title="About" />
      <Header />
      <Confetti />
      <section className={styles.about}>
        <div className={styles.about__confetti}>
          <img src="/about/confetti.png" alt="Confetti"></img>
        </div>
        <div className={styles.about__container}>
          <div className={styles.about__container__image}>
            <img src="/about/picture.png" alt="About"></img>
            {/* <img src="/about/picture-confetti.png" alt="About"></img> */}
          </div>
          <div className={styles.about__container__text}>
            <p className={styles.about__container__text__title}>About</p>
            <p>
              I am 26 and I still do not sleep well the night before my
              birthday. Through this artistic research project, I have figured
              out some of my own reasons why. I have become fascinated by the
              charged moment of social conventions, expectations, emotions and
              reflections that lie beneath a decorated layer of sweet cakes and
              brightly coloured plastic trash. Is the birthday a modern Trojan
              Horse?
            </p>
            <p>
              Since potentially (almost) everyone with a date of birth can
              somehow relate to the concept ‘birthday’, I see potential in using
              ‘the birthday’ as a medium for creating a collaborative daily life
              philosophy, full of self- and society-reflections. It gives me
              great pleasure to be the host and listening ear, who designs
              ‘space’ in which people can open up about their birthday
              experiences.
            </p>
            <p>
              And so, the happybirthdayto.[name] platform was born. An online
              space, somehow situated between a research institute and a
              birthday party, to which anyone with a date of birth is accepted
              and invited. In this open accessible archive many people from
              different points of view have responded to ‘what the birthday
              means and how to deal with it’. I am carefully gluing this daily
              life wisdom together, starting the collaborative ‘Philosophy of
              Birthdays’.
            </p>
            <p>
              For more thoughts on dealing with the yearly reoccurring
              unavoidable, advice on managing the unmanageable or recipes for
              birthday cakes, visit the institute spaces or pass by our
              real-life pop-up reception room.
            </p>
            <p>
              As the founding mother of happybirthdayto.[name], (I am wishing
              you a) Happy Birthday, Noelle
            </p>
          </div>
        </div>
      </section>
    </>
  )
}
