import { createStore } from "react-hooks-global-state"

export const { dispatch, useGlobalState } = createStore(
  (state, action) => {
    switch (action.type) {
      case "setFirstName":
        return {
          ...state,
          user: {
            ...state.user,
            firstName: action.firstName,
          },
        }
      case "setBirthday":
        return {
          ...state,
          user: {
            ...state.user,
            birthday: action.birthday,
          },
        }
      default:
        return state
    }
  },
  {
    user: {
      firstName: "",
      birthday: "",
    },
  }
)
