import React from "react"
import styles from "./styles.module.scss"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { useGlobalState as useUserGlobalState } from "../../states/user-state"

import {
  dispatch,
  useGlobalState as useSettingGlobalState,
} from "../../states/settings-state"

export default function Header({ menuIsVisible }) {
  const [user] = useUserGlobalState("user")
  const { firstName } = user

  const [settings] = useSettingGlobalState("settings")
  const { isMenuOpen } = settings

  const toggleMenu = () => {
    dispatch({
      isMenuOpen: !isMenuOpen,
      type: "setMenu",
    })
  }

  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <button
          style={
            menuIsVisible ? { visibility: "visible" } : { visibility: "hidden" }
          }
          className={styles.header__container__menu}
          onClick={toggleMenu}
        >
          menu
        </button>
        <Link className={styles.header__container__home} to="/">
          <h1>happybirthdayto.[{firstName ? firstName : "name"}]</h1>
        </Link>
      </div>
    </header>
  )
}

Header.defaultProps = {
  menuIsVisible: true,
}

Header.propTypes = {
  menuIsVisible: PropTypes.bool.isRequired,
}
